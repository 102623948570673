.whatsapp{
  height: 27px;
  width: 27px;
  cursor:pointer;
}
.well {
  height: auto;
  margin-bottom: 5%;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-image {
  max-width: 100%;
  height: 100%;
}
.bt {
  cursor:pointer;
}
.jumbotron {
  height: auto;
  margin-bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  max-width: 100%;
}

header {
  margin-top: 5%;
}

body {
  margin-top: 2%;
}

/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
  }
}